<template>
  <section v-if="currentStep" class="PriceCalculatorStep flex flex-col gap-5">
    <div class="flex items-center mb-[-0.75rem] gap-3">
      <ProgressIndicator
        class="flex-none"
        :max-value="stepsCount"
        :current-value="currentStepIndex + 1"
        :radius="isSmallScreen ? 18 : 25"
      />
      <h1 class="text-2xl lg:text-4xl font-bold text-primary">
        {{ currentStep.title }}
      </h1>
    </div>
    <div
      v-if="currentStep.type === TYPE_SELECT"
      class="grid grid-cols-1 lg:grid-cols-3 gap-5"
    >
      <SelectableBox
        v-for="option in currentStepOptions"
        :key="option.id"
        :item="option"
        :is-selected="option.id === currentStep.selectedOptionId"
        @onselected="selectOptionById"
      />
    </div>
    <div v-if="currentStep.type === TYPE_INPUT">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
        <SizeInputBox
          v-for="(entity, index) in currentStep.entities"
          :key="index"
          :index="index"
          :entity-name="currentStep.entityName"
          :inputs="currentStep.inputs"
          :entity-count="currentStep.entities.length"
          :entity="entity"
          :show-index="professionTask.seoName !== 'koltoztetes'"
          @input="updateEntityValue"
          @remove="removeEntity"
        />
      </div>
      <JoszakiButton
        v-if="professionTask.seoName !== 'koltoztetes'"
        icon-left="plus"
        icon-left-pack="fas"
        @click="addEntity"
      >
        {{
          $t("priceCalculator.newEntity", {
            entityName: currentStep.entityName,
          })
        }}
      </JoszakiButton>
    </div>
    <div v-if="currentStep.type === TYPE_RESULT" class="flex gap-5 flex-col">
      <ResultBox v-if="currentStep.results" :results="currentStep.results" />
      <JoszakiCard v-else type="error">
        {{ $t("priceCalculator.notEnoughInput") }}
      </JoszakiCard>
      <JoszakiButton
        v-if="isKh && currentStep.results"
        @click="onKhButtonClick"
      >
        <img v-lazy-load data-src="~/assets/img/price-calculator-loan.svg" class="pr-3" />
        {{ $t("priceCalculator.howMuchLoan") }}
      </JoszakiButton>
      <JoszakiCard>
        {{ $t("priceCalculator.disclaimer") }}
      </JoszakiCard>
    </div>

    <div
      class="flex flex-row items-stretch lg:items-start gap-5 max-lg:fixed max-lg:bg-white max-lg:p-3 border border-solid border-t-gray-300 lg:border-none left-0 right-0 bottom-0 max-lg:z-40"
    >
      <JoszakiButton
        v-if="currentStepIndex > 0"
        class="lg:!px-16"
        outline
        inverted
        :expanded="isSmallScreen"
        @click="$router.back()"
      >
        {{ $t("priceCalculator.prev") }}
      </JoszakiButton>
      <JoszakiButton
        v-if="nextStepSeoName"
        class="lg:!px-16"
        :expanded="isSmallScreen"
        @click="goToStep(nextStepSeoName)"
      >
        {{ $t("priceCalculator.next") }}
      </JoszakiButton>
    </div>

    <div
      v-if="showInfoButton"
      class="flex flex-row justify-start gap-3 items-center cursor-pointer"
      @click="setIsPopupShown(true)"
    >
      <IconComponent icon="info-circle" />
      <span class="pl-3 border-l-2 border-l-gray-500">{{
        currentStep.infoButtonLabel
      }}</span>
    </div>
    <JoszakiModal
      v-if="showInfoButton"
      size="sm"
      hide-footer
      :title="currentStep.infoButtonTitle"
      :open="isPopupShown"
      @close="setIsPopupShown(false)"
    >
      <template #body>
        <MarkdownTextBlock :text="currentStep.infoButtonContent" />
      </template>
    </JoszakiModal>

    <BlockTender
      v-if="showBlockTender"
      text-input
      :collapsed-by-default="isKh"
      :invert-colors="isKh"
      :title="$t('priceCalculator.blockTenderText')"
      :placeholder="quickOfferPlaceholder"
      @requestTender="
        onRequestTender({
          source: 'price-calculator-left',
          description: $event,
        })
      "
    />

    <JoszakiCard v-if="description && !isKh">
      <MarkdownTextBlock :text="description" />
    </JoszakiCard>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import SelectableBox from "~/components/_refactored/priceCalculatorPage/SelectableBox/SelectableBox.vue";
import MarkdownTextBlock from "~/components/_refactored/common/MarkdownTextBlock.vue";
import BlockTender from "~/components/_refactored/blocks/BlockTender.vue";
import SizeInputBox from "~/components/_refactored/priceCalculatorPage/SizeInputBox/SizeInputBox.vue";
import ResultBox from "~/components/_refactored/priceCalculatorPage/ResultBox/ResultBox.vue";
import ProgressIndicator from "~/components/_refactored/progressIndicator/ProgressIndicator.vue";
import {
  STEP_TYPE_INPUT,
  STEP_TYPE_RESULT,
  STEP_TYPE_SELECT,
} from "~/store/priceCalculator";
import { useRequestTender } from "~/composables/requestTender";
import { useBrand } from "~/composables/useBrand";

export default {
  name: "PriceCalculatorStep",
  components: {
    ProgressIndicator,
    ResultBox,
    SizeInputBox,
    BlockTender,
    MarkdownTextBlock,
    SelectableBox,
  },
  setup() {
    const { isSuperprofikCz } = useBrand();
    return {
      isSuperprofikCz,
      ...useRequestTender(),
    };
  },
  computed: {
    isSmallScreen() {
      return this.$mq === "mobile" || this.$mq === "tablet";
    },
    ...mapGetters("priceCalculator", [
      "professionTask",
      "profession",
      "currentStep",
      "currentStepSeoName",
      "currentStepIndex",
      "currentStepOptions",
      "stepsCount",
      "isPopupShown",
      "combinedDescriptions",
      "prevStepSeoName",
      "nextStepSeoName",
    ]),
    isKh() {
      return this.$route.query?.referrer === "kh";
    },
    isCzechia() {
      return this.$i18n.locale === "cs";
    },
    description() {
      if (this.currentStepIndex === 0 && !this.isKh) {
        return this.combinedDescriptions;
      }
      return this.currentStep.description;
    },
    showInfoButton() {
      return (
        this.currentStep.infoButtonLabel &&
        this.currentStep.infoButtonTitle &&
        this.currentStep.infoButtonContent
      );
    },
    showBlockTender() {
      return (
        this.currentStepIndex === 0 && !this.isSuperprofikCz && !this.isCzechia
      );
    },
    quickOfferPlaceholder() {
      const translationKey = `quickOfferPlaceholder.${this.profession?.seoName}`;
      if (this.$te(translationKey)) {
        return this.$t(translationKey);
      }
      return this.$t("quickOffer.placeholder");
    },
  },
  created() {
    this.TYPE_INPUT = STEP_TYPE_INPUT;
    this.TYPE_SELECT = STEP_TYPE_SELECT;
    this.TYPE_RESULT = STEP_TYPE_RESULT;
  },
  methods: {
    selectOptionById(optionId) {
      this.$store.dispatch("priceCalculator/setSelectedOptionById", {
        stepSeoName: this.currentStepSeoName,
        optionId,
      });
    },
    setIsPopupShown(isShown) {
      this.$store.commit("priceCalculator/setIsPopupShown", isShown);
    },
    addEntity() {
      this.$store.commit("priceCalculator/addEntity", {
        stepSeoName: this.currentStepSeoName,
      });
    },
    updateEntityValue({ entityIndex, variableName, value }) {
      this.$store.commit("priceCalculator/setEntityValue", {
        stepSeoName: this.currentStepSeoName,
        entityIndex,
        variableName,
        value,
      });
    },
    removeEntity(entityIndex) {
      this.$store.commit("priceCalculator/removeEntityAt", {
        stepSeoName: this.currentStepSeoName,
        entityIndex,
      });
    },
    goToStep(stepSeoName) {
      this.$joszaki.scrollToTop();

      const params = {
        professionTask: this.professionTask.seoName,
        step: stepSeoName,
      };

      if (!stepSeoName) {
        delete params.step;
      }

      const priceCalculatorStepPagePath = this.localePath({
        name: "priceCalculatorPage",
        params,
        query: Object.assign({}, this.$route.query),
      });
      this.$router.push(priceCalculatorStepPagePath);
    },
    onKhButtonClick() {
      const totalResultIndex = (
        this.currentStep.results?.totals?.rows ?? []
      ).findIndex((row) => row.label === "Összesen");
      const totalResultValue = (() => {
        if (totalResultIndex === -1) {
          return 500000;
        }
        return this.currentStep.results.totals.rows[totalResultIndex].value;
      })();
      const totalResultMaxValue =
        Math.floor((totalResultValue * 1.1) / 100) * 100;

      const queryParams = new URLSearchParams({
        p_p_id: "productcalculatorportlet_WAR_loanportlet",
        _productcalculatorportlet_WAR_loanportlet_loanAmount:
          totalResultMaxValue,
        _productcalculatorportlet_WAR_loanportlet_duration: "36",
        _productcalculatorportlet_WAR_loanportlet_discountIds: "",
        _productcalculatorportlet_WAR_loanportlet_noDiscount: "fals",
        _productcalculatorportlet_WAR_loanportlet_question: "yes",
        utm_source: "ingatlanfigyelo-app",
        utm_medium: "joszaki-festes",
        utm_content: "ingatlanfigyelo",
        utm_campaign: "szemelyi-kolcson-longterm",
      });

      const baseUrl = new URL(
        "https://www.kh.hu/hitel/szemelyi-kolcson/lakasfelujitas"
      );

      const url = `${baseUrl}?${queryParams.toString()}#cta`;
      window.open(url, "_blank", "noreferrer");
    },
    onRequestTender({ source, description }) {
      this.requestTender({
        source,
        description,
      });
    },
  },
};
</script>
